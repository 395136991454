import "bootstrap/dist/css/bootstrap.min.css"
import "jquery/dist/jquery.min.js"
import "popper.js/dist/popper.min"
import "bootstrap/dist/js/bootstrap.min.js"
// import 'aos/dist/aos.css'

import "./src/styles/styles.scss"
import "./src/styles/animate.scss"

export const onRouteUpdate = () => {
  if (typeof window !== `undefined`) {
    window.scrollTo(0, 0)
  }
}

export const shouldUpdateScroll = () => {
  return [0, 0]
}
